var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('span',[_vm._v("Takip Ek Alanları")]),_c('v-spacer'),_c('v-card',{attrs:{"elevation":"0"}},[_c('form-dialog',{attrs:{"disabled":!_vm.$helper.isAuthorized('takip-ek-alani-ekle'),"tooltip":!_vm.$helper.isAuthorized('takip-ek-alani-ekle')
            ? 'Bu işlem için yetkiniz yok.'
            : '',"title":"Takip Ek Alanı Türü Bilgileri","icon":"mdi-plus","medium":""},on:{"success":_vm.load},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onSuccess, onClose }){return [_c('takip-ek-alan-turu-form',{on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-header":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{class:h.class},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.tur",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.EkAlanTuruLabel[item.tur])+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('form-dialog',{attrs:{"disabled":!_vm.$helper.isAuthorized('takip-ek-alani-duzenle'),"tooltip":!_vm.$helper.isAuthorized('takip-ek-alani-duzenle')
            ? 'Bu işlem için yetkiniz yok.'
            : '',"title":"Takip Ek Alanı Türü Bilgileri","icon":"mdi-pencil","color":"#959595","medium":""},on:{"success":_vm.load},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onSuccess, onClose }){return [_c('takip-ek-alan-turu-form',{on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}}),_c('delete-dialog',{attrs:{"action":'/api/v1/takip-ek-alan-turu/' + item.id,"disabled":!_vm.$helper.isAuthorized('takip-ek-alani-sil'),"tooltip":!_vm.$helper.isAuthorized('takip-ek-alani-sil')
            ? 'Bu işlem için yetkiniz yok.'
            : ''},on:{"success":_vm.load}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }