
import {Component, Prop, Vue} from "vue-property-decorator";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";

@Component({
  components: {}
})

export default class SabitAlacakliPicker extends Vue {

  private vekaletler: Array<VekaletEntity> = []
  private muvekkiller: Array<MuvekkilEntity> = []
  private selectedMuvekkiller: Array<MuvekkilEntity> = [];
  private searchText: string = "";
  private activeStep: number = 1;
  disabled: boolean = false;
  is_default:number=0
  muvekkilHeaders = [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "Vergi / TC Kimlik No", value: "no"},
  ];

  vekaletHeaders = [
    {text: "Vekalet No", align: "start", value: "vekalet_no"},
    {text: "Müvekkiller", sortable: false, value: "muvekkiller"},
    {text: "Noter", value: "noter.isim"},
    {text: "Vekaletname Tarihi", value: "vekalet_tarihi"},
    {text: "Ekle", value: "actions", sortable: false}
  ];

  mounted() {
    this.load();
  }

  private load() {
    let action = "/api/v1/vekalet"
    if (this.searchText != "") {
      let no = parseInt(this.searchText);
      if (isNaN(no) || no > 999999999) {
        action = "/api/v1/vekalet/filter?keyword=" + this.searchText;
      } else {
        action = "/api/v1/vekalet/filter?no=" + this.searchText;
      }
    }

    this.$http.get(action)
        .then(response => {
          this.vekaletler = response.data;
        });

  }

  private selectVekalet(vekalet: VekaletEntity) {
    if (vekalet.muvekkiller.length == 1) {
      this.insertMuvekkiller(vekalet.muvekkiller);
    } else if (vekalet.muvekkiller.length > 1) {
      this.activeStep = 2;
      this.muvekkiller = vekalet.muvekkiller;
    }
  }

  private insertMuvekkiller(muvekkiller: Array<MuvekkilEntity>) {
    this.disabled=true;
    if (muvekkiller.length == 0) {
      this.close();
      this.disabled=false;
    }
    let data = {
      muvekkil_ids : muvekkiller.map(muv => muv.id),
      is_default:1
    };
    this.$http.put("/api/v1/set-muvekkil-default/", data).then(() => {
      this.$emit('load');
      this.$emit('onSuccess');
      this.close();
    }).finally(() => (this.disabled = false));
  }

  close() {
    this.muvekkiller = [];
    this.activeStep = 1;
    this.searchText = "";
    this.load();
    this.$emit('close');
  }
}
