
import {Vue, Component} from 'vue-property-decorator';
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TakipEkAlanTuruForm from "@/components/forms/TakipEkAlanTuruForm.vue";
import TakipAlacakliPicker from "@/components/pickers/TakipAlacakliPicker.vue";
import SabitAlacakliPicker from "@/components/pickers/SabitAlacakliPicker.vue";

@Component({
  components: {SabitAlacakliPicker, TakipAlacakliPicker, TakipEkAlanTuruForm, DeleteDialog, FormDialog}
})
export default class SabitAlacakliList extends Vue {

  items:any = []

  mounted(){
    this.load()
  }

  deleteSabitAlacak(item:any){
    let data = {
      muvekkil_ids : [item.id],
      is_default:0
    };
    this.$http.put("/api/v1/set-muvekkil-default/", data).then(() => {
     this.load()
    });
  }

  load() {
    this.$http.get("/api/v1/get-muvekkil-default").then((response) => {
      this.items =response.data
    })
  }

  headers= [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "Vergi / TC Kimlik No", value: "no"},
    {text: "İşlemler", value: "actions"},
  ]

}
